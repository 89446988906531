import en from './en.json';
import ru from './ru.json';

export const LOCALES = [
  {
    name: 'Russian',
    tag: 'ru',
    originalName: 'Русский',
    flagEmoji: '🇷🇺',
    base: true,
    res: ru,
  },
  {
    name: 'English',
    tag: 'en',
    originalName: 'English',
    flagEmoji: '🇬🇧',
    base: false,
    res: en,
  },
] as const;
