import { type IconButtonProps, Modal } from '@affine/component';
import type { DialogContentProps } from '@radix-ui/react-dialog';
import type { FC, PropsWithChildren } from 'react';

type AIChatModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  onOutside?: DialogContentProps['onPointerDownOutside'];
  onClose?: IconButtonProps['onClick'];
};

export const AIChatBaseModal: FC<PropsWithChildren<AIChatModalProps>> = ({
  children,
  open,
  setOpen,
  onOutside,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      width={400}
      closeButtonOptions={{
        onClick: onClose,
      }}
      contentOptions={{
        ['data-testid' as string]: 'auth-modal',
        style: {
          padding: '44px 40px',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
        onPointerDownOutside: event => onOutside?.(event),
      }}
    >
      {children}
    </Modal>
  );
};
