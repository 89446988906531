import {
  ConfirmModal,
  IconButton,
  notify,
  ScrollableContainer,
} from '@affine/component';
import {
  Chat,
  ChatInput,
  ChatMessages,
  type Message,
} from '@affine/component/chats';
import { openSettingModalAtom } from '@affine/core/components/atoms';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { AuthService, FetchService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { CloseIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useSetAtom } from 'jotai';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useRef, useState } from 'react';

import { OnboardingApiKey } from './ai-chat-onboarding-api-key';
import { deleteChat, getChat, getMessage } from './api';
import { ID_AI_CHAT, NAME_AI_CHAT, URL_AI_CHAT_AVATAR_LOGO } from './constant';
import { useEnabledAiChat, useMessagesChat } from './hooks';
import * as styles from './index.css';
import { isErrorEventSource } from './model';
import { ChatClearIcon, OpenWebUIIcon, TextTypingEffect } from './ui';

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, y: '100%' },
  initial: { opacity: 0, y: 0 },
};

const variantsChat = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '100%' },
  initial: { opacity: 0, y: 0 },
};

export const AiChatIsland = () => {
  const fetcher = useService(FetchService);
  const t = useI18n();

  const session = useService(AuthService).session;
  const account = useLiveData(session.account$);

  const setSettingModalAtom = useSetAtom(openSettingModalAtom);

  const [messages, setMessages] = useState<Message[]>([]);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [aiChatOpened, setAiChatOpened] = useState(false);
  const [sentMessage, setSentMessage] = useState<Message | null>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const { getMessagesFromChat } = useMessagesChat();
  const { existApiKey, enabled } = useEnabledAiChat(account?.id);

  useEffect(() => {
    if (!account || !existApiKey) return;
    const getMessages = async () => {
      const aiChat = await getChat(account.id || '', fetcher);

      setMessages(getMessagesFromChat(aiChat, account) || []);
    };

    getMessages().catch(error => {
      if (error instanceof Error) {
        notify.error({
          message: error.message,
          title: 'Не удалось получить чат',
        });
      }
    });
  }, [account, fetcher, existApiKey, getMessagesFromChat]);

  const scrollCharDown = useCallback(() => {
    requestAnimationFrame(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scroll({
          top: messagesContainerRef.current.scrollHeight,
          behavior: 'auto',
        });
      }
    });
  }, [messagesContainerRef]);

  useEffect(() => {
    scrollCharDown();
  }, [messages, scrollCharDown]);

  useEffect(() => {
    if (sentMessage && account?.id) {
      getMessageAi(account.id, sentMessage)
        .catch(error => {
          if (isErrorEventSource(error)) {
            notify.error({
              message: error.message,
              title: 'Не удалось получить сообщение',
            });
          }
        })
        .finally(() => {
          setSentMessage(null);
        });
    }
  }, [sentMessage, account?.id]);

  const sendMessage = useCallback(
    (newMessage: Message) => {
      if (!account) return;

      const message = {
        ...newMessage,
        user: {
          id: account.id,
          name: account.info?.name || '',
          avatar: account.avatar,
        },
      };

      const messageAi: Message = {
        id: nanoid(),
        message: '',
        user: {
          id: ID_AI_CHAT,
          name: NAME_AI_CHAT,
          avatar: URL_AI_CHAT_AVATAR_LOGO,
        },
        status: 'pending',
      };

      setMessages(state => [...state, message, messageAi]);

      setSentMessage(message);
    },
    [account, setMessages, setSentMessage]
  );

  const getMessageAi = async (userId: string, message: Message) => {
    const result = await getMessage(userId, message.message);

    setMessages(state => {
      const last = state.pop();
      if (last) {
        last.message = result;
        return [...state, last];
      }

      return state;
    });
  };

  const onCloseDeleteConfirmModal = useCallback(() => {
    setShowDeleteConfirmModal(false);
  }, [setShowDeleteConfirmModal]);

  const clearHistoryChat = useAsyncCallback(async () => {
    const isDeleted = await deleteChat(account?.id || '', fetcher);
    if (isDeleted) {
      setMessages([]);
    }
    onCloseDeleteConfirmModal();
  }, [onCloseDeleteConfirmModal, account?.id, fetcher, setMessages]);

  const onOpenAccountSetting = useCallback(() => {
    setSettingModalAtom(prev => ({
      ...prev,
      open: true,
      activeTab: 'account',
    }));
  }, [setSettingModalAtom]);

  const onCompleteTypingMessage = useCallback(() => {
    setMessages(state => {
      const last = state.pop();
      if (last) {
        last.status = 'done';
        return [...state, last];
      }
      return state;
    });
  }, [setMessages]);

  const onChangeTypingMessage = useCallback(() => {
    scrollCharDown();
  }, [scrollCharDown]);

  return (
    enabled && (
      <>
        <motion.div
          animate={!aiChatOpened ? 'open' : 'closed'}
          variants={variants}
          initial="initial"
          className={clsx(
            styles.aiChatContainerStyle,
            styles.aiChatIslandBtnContainerStyle
          )}
        >
          <button
            onClick={() => setAiChatOpened(!aiChatOpened)}
            className={styles.aiChatIslandBtnStyle}
          >
            <OpenWebUIIcon />
          </button>
        </motion.div>
        <motion.div
          animate={aiChatOpened ? 'open' : 'closed'}
          variants={variantsChat}
          initial="initial"
          className={clsx(styles.aiChatContainerStyle, styles.aiChatStyle)}
        >
          {existApiKey ? (
            <Chat>
              <ScrollableContainer scrollRef={messagesContainerRef}>
                {account && (
                  <ChatMessages
                    messages={messages}
                    currentUser={{
                      avatar: account.avatar,
                      id: account.id,
                      name: account.info?.name || '',
                    }}
                    renderTextMessage={(text, { message, render }) => {
                      return message.status === 'pending' ? (
                        <TextTypingEffect
                          message={message.message}
                          render={typingText => render(typingText)}
                          onChange={onChangeTypingMessage}
                          onComplete={onCompleteTypingMessage}
                        />
                      ) : (
                        render(text)
                      );
                    }}
                  />
                )}
              </ScrollableContainer>
              <ChatInput
                actionsLeft={() => (
                  <IconButton onClick={() => setShowDeleteConfirmModal(true)}>
                    <ChatClearIcon />
                  </IconButton>
                )}
                sendMessage={sendMessage}
              />
            </Chat>
          ) : (
            <OnboardingApiKey
              onClickLink={() => onOpenAccountSetting()}
              className={styles.aiChatOnboardingApiKeyContainerStyle}
            />
          )}
          <IconButton
            onClick={() => setAiChatOpened(false)}
            className={styles.aiChatCloseBtnStyle}
          >
            <CloseIcon />
          </IconButton>
        </motion.div>
        <ConfirmModal
          open={showDeleteConfirmModal}
          closeButtonOptions={{
            onClick: onCloseDeleteConfirmModal,
          }}
          title={t['docnboard.ai.history.title.clear']()}
          description={t['docnboard.ai.history.description.clear']()}
          cancelText={t['com.affine.nameWorkspace.button.cancel']()}
          onCancel={onCloseDeleteConfirmModal}
          onConfirm={clearHistoryChat}
          cancelButtonOptions={{
            onClick: onCloseDeleteConfirmModal,
          }}
          confirmButtonOptions={{
            variant: 'error',
          }}
          confirmText={t['Confirm']()}
        />
      </>
    )
  );
};
