import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { AIChatBaseModal } from './ai-chat-base-modal';
import { AIChatUpdateAPIKey } from './ai-chat-update-api-key';
import { type AIChat, aiChatAtom } from './model';

export const AIChatModal = () => {
  const [{ openModal, state, userId }, setAiChatAtom] = useAtom(aiChatAtom);

  const setOpen = useCallback(
    (open: boolean) => {
      setAiChatAtom(prev => ({ ...prev, openModal: open }));
    },
    [setAiChatAtom]
  );

  const setAIChatState: AIChat['setAIChatState'] = useCallback(
    state => {
      setAiChatAtom(prev => ({ ...prev, state }));
    },
    [setAiChatAtom]
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <AIChatBaseModal
      open={openModal}
      setOpen={setOpen}
      onOutside={onClose}
      onClose={onClose}
    >
      {state === 'updateAPIKey' && (
        <AIChatUpdateAPIKey
          userId={userId}
          state={state}
          setAIChatState={setAIChatState}
          onClose={onClose}
        />
      )}
    </AIChatBaseModal>
  );
};
