import { type IDBPDatabase, openDB } from 'idb';

import type { DocDBSchema } from '../../impls/engine/doc-indexeddb';
import type { Migration } from '../../migration';

export class MigrationAffineLocalToDnbLocal implements Migration<DocDBSchema> {
  db: IDBPDatabase<DocDBSchema>;
  oldVersion: number;
  newVersion: number | null;

  constructor(
    db: IDBPDatabase<DocDBSchema>,
    oldVersion: number,
    newVersion: number | null
  ) {
    this.db = db;
    this.oldVersion = oldVersion;
    this.newVersion = newVersion;
  }

  async run() {
    const existingOldDb = await indexedDB
      .databases()
      .then(dbs => dbs.some(db => db.name === 'affine-local'));

    if (existingOldDb && this.oldVersion === 0 && this.newVersion === 2) {
      const oldDb = await openDB<DocDBSchema>('affine-local');
      const oldStore = oldDb.transaction('workspace').store;
      const keysToTransfer = await oldStore.getAllKeys();
      const values = await Promise.all(
        keysToTransfer.map(async key => {
          return await oldStore.get(key);
        })
      );

      const tx = this.db.transaction('workspace', 'readwrite');

      await Promise.all(
        values.map(async value => {
          if (value) {
            await tx.store.put({
              id: value?.id,
              updates: value?.updates,
            });
          }
        })
      );

      await tx.done;

      location.reload();
    }
  }
}
