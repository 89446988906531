import {
  getMembersByWorkspaceIdQuery,
  type WorkspaceFilterInput,
} from '@affine/graphql';
import { Store } from '@toeverything/infra';

import type { GraphQLService } from '../../cloud';

export class WorkspaceMembersStore extends Store {
  constructor(private readonly graphqlService: GraphQLService) {
    super();
  }

  async fetchMembers(
    workspaceId: string,
    skip: number,
    take: number,
    // DNB_FIX
    filter?: WorkspaceFilterInput,
    signal?: AbortSignal
  ) {
    const data = await this.graphqlService.gql({
      query: getMembersByWorkspaceIdQuery,
      variables: {
        filter,
        workspaceId,
        skip,
        take,
      },
      context: {
        signal,
      },
    });

    return data.getWorkspace;
  }
}
