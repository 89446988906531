import type { Message } from '@affine/component/chats';
import { FetchService, ServerConfigService } from '@affine/core/modules/cloud';
import { useLiveData, useService } from '@toeverything/infra';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { getApiKey } from './api';
import { ID_AI_CHAT, NAME_AI_CHAT, URL_AI_CHAT_AVATAR_LOGO } from './constant';
import { type Account, type AiChat, apiKeyAtom } from './model';

export const useTypingEffect = (
  text: string,
  duration: number = 2000,
  isTypeByLetter = false
): string => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const items = isTypeByLetter ? text.split('') : text.split(' ');

  useEffect(() => {
    setCurrentPosition(0);
  }, [text]);

  useEffect(() => {
    if (currentPosition >= items.length) return;

    const intervalId = setInterval(() => {
      setCurrentPosition(prevPosition => prevPosition + 1);
    }, duration);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPosition, items, duration]);

  return items.slice(0, currentPosition).join(isTypeByLetter ? '' : ' ');
};

export const useMessagesChat = () => {
  const getMessagesFromChat = (
    aiChat: AiChat,
    account: Account
  ): Message[] | undefined => {
    return aiChat.chat?.messages.map(message => {
      const user = {
        id: account.id,
        name: account.info?.name || '',
        avatar: account.avatar,
      };
      if (message.role === 'assistant') {
        user.id = ID_AI_CHAT;
        user.name = NAME_AI_CHAT;
        user.avatar = URL_AI_CHAT_AVATAR_LOGO;
      }

      return {
        id: message.id,
        message: message.content,
        user,
        status: 'done',
      };
    });
  };

  return { getMessagesFromChat };
};

export const useEnabledAiChat = (userId: string | undefined) => {
  const [existApiKey, setExistApiKey] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const enabledChatbot = useEnableChatbot();

  const { apiKey } = useApiKey(userId);

  useEffect(() => {
    setEnabled(!!enabledChatbot && !!userId);
    setExistApiKey(!!apiKey);
  }, [apiKey, userId, setExistApiKey, setEnabled, enabledChatbot]);

  return { existApiKey, enabled };
};

export const useApiKey = (userId: string | undefined) => {
  const [apiKey, setApiKey] = useAtom(apiKeyAtom);
  const fetcher = useService(FetchService);

  const enabledChatbot = useEnableChatbot();

  useEffect(() => {
    if (!userId) return;
    if (!enabledChatbot) return;

    getApiKey(userId, fetcher)
      .then((apiKey: string) => {
        setApiKey(apiKey);
      })
      .catch(error => console.error(error));
  }, [fetcher, userId, setApiKey, enabledChatbot]);

  return { apiKey, setApiKey };
};

export const useEnableChatbot = () => {
  const serverConfig = useService(ServerConfigService).serverConfig;
  const enabledChatbot = useLiveData(
    serverConfig.features$.map(f => f?.chatbot)
  );
  return enabledChatbot;
};
