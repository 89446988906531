import clsx from 'clsx';
import { motion } from 'framer-motion';
import { forwardRef, type LegacyRef } from 'react';

import { Button, type ButtonType } from '../button';
import { SafeArea } from '../safe-area';
import {
  cookieBannerWrapperStyle,
  cookieButtonsStyle,
  cookieButtonStyle,
  cookieSectionStyle,
} from './index.css';

export interface IBannerButtons {
  type: ButtonType;
  text: string;
  color: boolean;
  onClick: () => void;
}

export interface IProps {
  renderContent: () => JSX.Element;
  buttons: IBannerButtons[];
  icon?: () => JSX.Element;
  position?: 'left' | 'right';
}

const Banner = forwardRef((props: IProps, ref: LegacyRef<HTMLDivElement>) => {
  const { renderContent, buttons, icon, position } = props;

  return (
    <SafeArea
      className={clsx(cookieBannerWrapperStyle, {
        left: position === 'left',
      })}
      ref={ref}
    >
      <div className={cookieSectionStyle}>
        {icon?.()}
        {renderContent()}
      </div>
      <div className={cookieButtonsStyle}>
        {buttons.map((item, index) => (
          <Button
            key={index}
            variant={item.type as ButtonType}
            className={item.color ? cookieButtonStyle : undefined}
            size="large"
            onClick={item.onClick}
          >
            {item.text}
          </Button>
        ))}
      </div>
    </SafeArea>
  );
});

export const MotionBanner = motion.create(Banner, {
  forwardMotionProps: true,
});
