import type { ReactNode } from 'react';

import { CookieBanner } from '../cookie-banner';
import * as styles from './index.css';

// DNB_FIX скрыть информацию
export const AffineOtherPageLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div className={styles.root}>
      {children}
      <CookieBanner />
    </div>
  );
};
