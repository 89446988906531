import { DocLinksService } from '@affine/core/modules/doc-link';
import { useI18n } from '@affine/i18n';
import {
  GlobalContextService,
  useLiveData,
  useServices,
  WorkspaceService,
} from '@toeverything/infra';
import { useCallback, useState } from 'react';

import {
  AffinePageReference,
  AffineSharedPageReference,
} from '../../affine/reference-link';
import * as styles from './bi-directional-link-panel.css';

export const BiDirectionalLinkPanel = () => {
  const [show, setShow] = useState(false);
  const { docLinksService } = useServices({
    DocLinksService,
  });
  const t = useI18n();

  const links = useLiveData(docLinksService.links.links$);
  const backlinks = useLiveData(docLinksService.backlinks.backlinks$);
  const handleClickShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  //DNB_FIX add shareMode

  const { workspaceService, globalContextService } = useServices({
    WorkspaceService,
    GlobalContextService,
  });

  const shareMode = workspaceService.workspace.openOptions.isSharedMode;

  const docCollection = workspaceService.workspace.docCollection;

  const docMode = useLiveData(globalContextService.globalContext.docMode.$);

  const shareParams = new URLSearchParams(docMode ? { mode: docMode } : {});

  return (
    <div className={styles.container}>
      {!show && (
        <div className={styles.dividerContainer}>
          <div className={styles.divider}></div>
        </div>
      )}

      <div className={styles.titleLine}>
        <div className={styles.title}>
          {t['com.affine.page-properties.links']()}
        </div>
        <div className={styles.showButton} onClick={handleClickShow}>
          {show
            ? t['com.affine.page-properties.links.hide']()
            : t['com.affine.page-properties.links.show']()}
        </div>
      </div>

      {show && (
        <>
          <div className={styles.dividerContainer}>
            <div className={styles.divider}></div>
          </div>
          <div className={styles.linksContainer}>
            <div className={styles.linksTitles}>
              {t['com.affine.page-properties.backlinks']()} · {backlinks.length}
            </div>
            {backlinks.map(link => (
              <div key={link.docId} className={styles.link}>
                {shareMode ? (
                  <AffineSharedPageReference
                    pageId={link.docId}
                    docCollection={docCollection}
                    params={shareParams}
                  />
                ) : (
                  <AffinePageReference key={link.docId} pageId={link.docId} />
                )}
              </div>
            ))}
          </div>
          {/* DNB_FIX перевести */}
          <div className={styles.linksContainer}>
            <div className={styles.linksTitles}>
              {t['com.affine.page-properties.outgoing-links']()} ·{' '}
              {links.length}
            </div>
            {links.map((link, i) => (
              <div
                key={`${link.docId}-${link.params?.toString()}-${i}`}
                className={styles.link}
              >
                {shareMode ? (
                  <AffineSharedPageReference
                    pageId={link.docId}
                    docCollection={docCollection}
                    params={shareParams}
                  />
                ) : (
                  <AffinePageReference
                    pageId={link.docId}
                    params={link.params}
                  />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
