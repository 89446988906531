import { UserAccessRole } from '@affine/graphql';
import { useI18n } from '@affine/i18n';

export const usePermission = () => {
  const t = useI18n();

  const getName = (permission: UserAccessRole): string => {
    switch (permission) {
      case UserAccessRole.Owner:
        return t['Workspace Owner']();
      case UserAccessRole.Admin:
        return '';
      case UserAccessRole.Read:
        return t['Reader']();
      case UserAccessRole.Write:
        return t['Editor']();
      default:
        return '';
    }
  };

  return { getName };
};
