import { AsyncQueue } from '@toeverything/infra';
import { type IDBPDatabase } from 'idb';

// TODO вынести в infra
export interface Migration<T> {
  db: IDBPDatabase<T>;

  oldVersion: number;
  newVersion: number | null;

  run(): Promise<void>;
}

export class Migrations<T> {
  migrations: AsyncQueue<Migration<T>>;

  constructor() {
    this.migrations = new AsyncQueue();
  }

  add(...migration: Migration<T>[]) {
    this.migrations.push(...migration);
  }

  async run() {
    while (this.migrations.length) {
      const migration = await this.migrations.next();
      await migration.run();
    }
  }
}
