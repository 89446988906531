import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Checkbox,
  cn,
  Loading,
  Modal,
  notify,
  ScrollableContainer,
} from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { popupWindow } from '@affine/core/utils';
import { getBaseUrl } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { ZipTransformer } from '@blocksuite/affine/blocks';
import { noop } from '@blocksuite/affine/global/utils';
import { useService, WorkspaceService } from '@toeverything/infra';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getFileImportBoard, useGetBoards, useIsAuthorized } from './api';
import { type Board, miroAtom } from './model';

export const MiroPanel = () => {
  const t = useI18n();

  const docCollection = useService(WorkspaceService).workspace.docCollection;

  const [{ openModal }, setMiroAtom] = useAtom(miroAtom);

  const [selectedBoards, setSelectedBoards] = useState<Board[]>([]);
  const [isPendingImport, setIsPendingImport] = useState<boolean>(false);

  const isAuthorizedQuery = useIsAuthorized({ enabled: openModal });
  const boardsQuery = useGetBoards({
    enabled: openModal && !!isAuthorizedQuery.data?.data && !isPendingImport,
  });

  const isAllSelected = useMemo(
    () => selectedBoards.length === boardsQuery.data?.length,
    [selectedBoards, boardsQuery.data]
  );

  const titleSelect = useMemo(
    () =>
      isAllSelected
        ? t['docnboard.miro.import.deselect-all']()
        : t['docnboard.miro.import.select-all'](),
    [isAllSelected, t]
  );

  const closeModal = useCallback(() => {
    if (isPendingImport) return;

    setMiroAtom(prev => ({ ...prev, openModal: false }));
    setIsPendingImport(false);
  }, [setMiroAtom, setIsPendingImport, isPendingImport]);

  const cleanSelectedBoards = useCallback(() => {
    setSelectedBoards([]);
  }, [setSelectedBoards]);

  const toggleAllBoards = useCallback(() => {
    setSelectedBoards([...(isAllSelected ? [] : (boardsQuery.data ?? []))]);
  }, [setSelectedBoards, isAllSelected, boardsQuery.data]);

  useEffect(() => {
    if (!isAuthorizedQuery.data) return;
    if (!isAuthorizedQuery.data.data) {
      closeModal();
      popupWindow(`${getBaseUrl()}/api/miro/auth`);
    }
  }, [closeModal, isAuthorizedQuery.data]);

  const onImportBoard = useAsyncCallback(async () => {
    let isErrorImport = false;
    setIsPendingImport(true);
    for await (const board of selectedBoards) {
      try {
        const file = await getFileImportBoard(board);
        await ZipTransformer.importDocs(docCollection, file);
      } catch (error) {
        isErrorImport = true;
        if (error instanceof Error) {
          notify.error({
            title: t['docnboard.miro.import.error.title'](),
            message: error.message,
          });
        }
      }
    }
    setIsPendingImport(false);

    if (isErrorImport) return;

    cleanSelectedBoards();
    closeModal();
  }, [closeModal, docCollection, selectedBoards, t, cleanSelectedBoards]);

  const toggleSelectedBoard = useCallback(
    (board: Board) => {
      setSelectedBoards(prev => {
        const idx = prev.findIndex(b => b.boardId === board.boardId);
        if (idx !== -1) {
          prev.splice(idx, 1);
        } else {
          prev.push(board);
        }

        return [...prev];
      });
    },
    [setSelectedBoards]
  );

  return (
    <Modal
      open={openModal}
      width={1280}
      height={920}
      contentOptions={{
        className:
          'flex !p-0 overflow-hidden md:max-w-2xl xl:max-w-6xl max-w-xs !max-h-[85vh]',
      }}
      onOpenChange={closeModal}
      withoutCloseButton={isPendingImport}
    >
      <div className="w-full flex flex-col p-10 space-y-4">
        <h1 className="text-2xl font-bold mb-1">
          {t['docnboard.miro.import.title']()}
        </h1>
        <ScrollableContainer scrollableContainerClassName="pr-5">
          {boardsQuery.isPending ? (
            <div className="w-full h-full flex justify-center items-center">
              <Loading size="5rem" />
            </div>
          ) : (
            <div className="h-full flex flex-col justify-between gap-4">
              <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {isPendingImport && (
                  <div className="absolute w-full h-full z-50 bg-white/80"></div>
                )}
                {boardsQuery.data?.map(board => {
                  return (
                    <Card
                      key={board.boardId}
                      onClick={() => toggleSelectedBoard(board)}
                      className={cn(
                        'relative hover:cursor-pointer m-1 outline-none hover:outline-[--affine-primary-color]',
                        {
                          'outline outline-[--affine-primary-color]':
                            selectedBoards.some(
                              b => b.boardId === board.boardId
                            ),
                        }
                      )}
                    >
                      <Checkbox
                        className="!absolute top-1 left-1 text-2xl text-black"
                        checked={selectedBoards.some(
                          b => b.boardId === board.boardId
                        )}
                        onChange={noop}
                      />
                      <div className="w-full h-48 rounded-t-lg">
                        {board.picture.imageURL && (
                          <img
                            src={board.picture.imageURL}
                            alt="Cover"
                            className="w-full h-full object-cover"
                          />
                        )}
                      </div>
                      <CardHeader>
                        <CardTitle className="overflow-hidden text-ellipsis">
                          {board.name}
                        </CardTitle>
                      </CardHeader>
                    </Card>
                  );
                })}
              </div>
            </div>
          )}
        </ScrollableContainer>
        <div className="flex flex-col md:flex-row xl:flex-row justify-end gap-2">
          <Button
            variant="secondary"
            className="h-8 w-8 p-0"
            size="large"
            onClick={toggleAllBoards}
            disabled={isPendingImport}
          >
            <span>{titleSelect}</span>
          </Button>
          <Button
            variant="primary"
            className="h-8 w-8 p-0"
            size="large"
            onClick={onImportBoard}
            disabled={selectedBoards.length === 0}
            loading={isPendingImport}
          >
            <span>{t['docnboard.miro.import.button']()}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
