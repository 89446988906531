import { useI18n } from '@affine/i18n';
import type { HTMLAttributes } from 'react';

import * as styles from './index.css';

export interface OnboardingApiKeyProps extends HTMLAttributes<HTMLDivElement> {
  onClickLink?: () => void;
}

export const OnboardingApiKey = ({
  className,
  onClickLink,
}: OnboardingApiKeyProps) => {
  const t = useI18n();

  return (
    <div className={className}>
      <h2 className={styles.aiChatOnboardingApiKeyTitleStyle}>
        {t['docnboard.ai.api-key.dont-add']()}
      </h2>
      <span>
        {t['docnboard.ai.api-key.you-need-add-1']()}
        <a
          onClick={onClickLink}
          className={styles.aiChatOnboardingApiKeyLinkStyle}
        >
          {t['docnboard.ai.api-key.you-need-add-2']()}
        </a>
        {t['docnboard.ai.api-key.you-need-add-3']()}
      </span>
    </div>
  );
};
