import type { BlobStorage } from '@toeverything/infra';

export const predefinedStaticFiles = [
  '1MEi3wQzNQ29gxpZ7SeqFJ13AFac5J9WJb17-_2LYqE=',
  '9vXwWGEX5W9v5pzwpu0eK4pf22DZ_sCloO0zCH1aVQ4=',
  'OU4yI29nOG7jBpE15ALCiGTNfW3Ysl6wFrbQoAu5CQQ=',
  'G_V93WJPJ0ijd3Fmk4v9lKWINJU2KgisdYoviyew-tQ=',
  'FVASmFi5FlATW6TlRhLDSaOKTTNPUcoJllgEaiR_PTw=',
  '2LEk4vKmYjOyCP_zzGpJZs15lUH-1ZzENDCY6aD9zqA=',
  'I4ZgQLmooQLfzA9edRuUtmHnh1SxYlkuA1khffsqSFA=',
  '5Pcp35VNRNos00kLiSxM_TDxUIByOJEZQQZfsjvaLtQ=',
];

export class StaticBlobStorage implements BlobStorage {
  name = 'static';
  readonly = true;
  async get(key: string) {
    const isStaticResource =
      predefinedStaticFiles.includes(key) || key.startsWith('/static/');

    if (!isStaticResource) {
      return null;
    }

    const path = key.startsWith('/static/') ? key : `/static/${key}`;
    const response = await fetch(path);

    if (response.ok) {
      return await response.blob();
    }

    return null;
  }

  async set(key: string) {
    // ignore
    return key;
  }
  async delete() {
    // ignore
  }
  async list() {
    // ignore
    return [];
  }
}
