import "../../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW3W6bMBi971NYrSZtF46Akp9S9aZSn2KaJoM/wI2xkW3SdNPefbKBxBBoSG4i2d/P8fH5jln9DrP3bbyPA/T3DqGKqIIJbGSdoMegPj7f/btb9SGhF5Ig0hj5fE5JpTGySlBskxAycDSYcFaIBGUgDKhBqWimG0KZ5FIl6EDUd4xJnjMB2BXTkElBifrELuSHDc6lMFizPzBKaNcrF8PtQgmsKE2CwtV6gONxGQ4q0hZEyYqS21I4lwoKJRtBsQJ6Hc1RX0cTIeLwdL0fwrenzdvrKGTFmdi3YY3SNq6WrCV4mj0bf+LMKxW7IrXUzDBpLzTVkjcGbB3VAgyeR+zgaDMSxdpnsBfBhXQ2/rnm0SGUkmzf0pogo4jQNVEgjNuSioLCXZHR5s3cR1F7z40GhTVwyEyChBQwwL11uJfI7CbdXie9ZzIYwNk5OP0Rttv2CJTpmpPPBOUc3IL9x5QpyNommeRNJezOe6MNyy0UYUCY82wi5IYVMwOV9pe/dIQnh+ei/xddClInaNfinryP61x7/cmsrBz1tWLVhGF8dC03wZDd1FX7YNSUCYrXi4wsG+QE8znLVNSxzSG39rCZt8SUN3B5onh0IurQTcO55dZmtLFk6Ab41sGFo2w6nsfPyFhqMC21JRBOEgs8iY0gLfTSulGAP0pmYORWePKSFOl32rEnlDJRJCjon8nO0xShrNGnx/PWm/Bp+kmJIZhpnAJncAD1cm9UA/e/HH+XgB8et3G4DgdF8lnZeEHF2RoH8rvJLh927jc/NN1k7dbfzqfXwPMJi+qlEwYDRTmVhWM5ldNycvYUdlY0o/qxnAZ9okk1t8tee9aSx+GYoNAvGniqOGXXR6QlZ3RI5yHCnHyCwkxoRuG1T7J/Q5t8n7XJQzR6pBZ+yHjF91eLdza8+Bvpcj69dvxqO/tFcfsxqqV1vRwx40mzz+/09P4H0tJCGYQLAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_1cj74k4d';
export var authMessage = '_1cj74k42';
export var authModalContent = '_1cj74k40';
export var authModalText = '_1cj74k4g';
export var captchaWrapper = '_1cj74k41';
export var errorMessage = '_1cj74k43';
export var forgetPasswordButton = '_1cj74k47';
export var forgetPasswordButtonRow = '_1cj74k44';
export var legalContainerStyle = '_1cj74k4f';
export var linkButton = '_1cj74k46';
export var resendCountdown = '_1cj74k4b';
export var resendCountdownInButton = '_1cj74k4c';
export var resendWrapper = '_1cj74k48';
export var sendMagicLinkButtonRow = '_1cj74k45';
export var sentMessage = '_1cj74k4a';
export var sentRow = '_1cj74k49';
export var skipDivider = '_1cj74k4h';
export var skipDividerLine = '_1cj74k4i';
export var skipDividerText = '_1cj74k4j';
export var skipLink = '_1cj74k4l';
export var skipLinkIcon = '_1cj74k4m';
export var skipSection = '_1cj74k4n';
export var skipText = '_1cj74k4k';
export var userPlanButton = '_1cj74k4e';