import { Loading, Switch, Tooltip } from '@affine/component';
import { SettingHeader } from '@affine/component/setting-components';
import { useI18n } from '@affine/i18n';
import {
  AFFINE_FLAGS,
  FeatureFlagService,
  useLiveData,
  useServices,
} from '@toeverything/infra';
import { Suspense, useCallback } from 'react';

import * as styles from './index.css';

const ExperimentalFeaturesItem = ({
  flag,
  //DNB_FIX добавить flagKey
  flagKey,
}: {
  flag: FeatureFlagService['flags'][keyof AFFINE_FLAGS];
  flagKey: string;
}) => {
  const t = useI18n();
  const value = useLiveData(flag.$);
  const onChange = useCallback(
    (checked: boolean) => {
      flag.set(checked);
    },
    [flag]
  );

  if (flag.configurable === false || ('hide' in flag && flag.hide)) {
    return null;
  }

  const displayName = t[
    `docnboard.flags.${flagKey}.name` as keyof typeof t
  ] as () => string;
  const description = t[
    `docnboard.flags.${flagKey}.description` as keyof typeof t
  ] as () => string;

  return (
    <div className={styles.rowContainer}>
      <div className={styles.switchRow}>
        {displayName()}
        <Switch checked={value} onChange={onChange} />
      </div>
      {!!flag.description && (
        <Tooltip content={description()}>
          <div className={styles.description}>{description()}</div>
        </Tooltip>
      )}
    </div>
  );
};

const ExperimentalFeaturesMain = () => {
  const t = useI18n();
  const { featureFlagService } = useServices({ FeatureFlagService });

  return (
    <>
      <SettingHeader
        title={t[
          'com.affine.settings.workspace.experimental-features.header.plugins'
        ]()}
        subtitle={t[
          'com.affine.settings.workspace.experimental-features.header.subtitle'
        ]()}
      />
      <div
        className={styles.settingsContainer}
        data-testid="experimental-settings"
      >
        {Object.keys(AFFINE_FLAGS).map(key => (
          <ExperimentalFeaturesItem
            key={key}
            flag={featureFlagService.flags[key as keyof AFFINE_FLAGS]}
            //DNB_FIX добавить flagKey
            flagKey={key}
          />
        ))}
      </div>
    </>
  );
};

// DNB_FIX
export const ExperimentalFeatures = () => {
  return (
    <Suspense fallback={<Loading />}>
      <ExperimentalFeaturesMain />
    </Suspense>
  );
};
