import { Button, IconButton, Input, notify, Wrapper } from '@affine/component';
import { AuthContent, ModalHeader } from '@affine/component/auth-components';
import { FetchService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { useService } from '@toeverything/infra';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { updateApiKey } from './api';
import { useApiKey, useEnabledAiChat } from './hooks';
import * as styles from './index.css';
import { type AIChat } from './model';
import { EyeOpenIcon, EyeSlashIcon } from './ui';

export const AIChatUpdateAPIKey = ({ userId, onClose }: AIChat) => {
  const t = useI18n();

  const fetcher = useService(FetchService);

  const { apiKey, setApiKey } = useApiKey(userId);

  const [showApiKey, setShowApiKey] = useState(false);
  const [apiKeyLocal, setApiKeyLocal] = useState(apiKey);

  useEffect(() => {
    setApiKeyLocal(apiKey);
  }, [apiKey]);

  const { existApiKey } = useEnabledAiChat(userId);

  const description = useMemo(
    () =>
      existApiKey
        ? t['docnboard.ai.api-key.title.update']()
        : t['docnboard.ai.api-key.title.add'](),
    [existApiKey, t]
  );

  const onApiKeyChange = useCallback(
    (value: string) => {
      setApiKeyLocal(value.trim());
    },
    [setApiKeyLocal]
  );

  const onTogleShowApiKey = useCallback(() => {
    setShowApiKey(show => !show);
  }, [setShowApiKey]);

  const onUpdateAPIKey = useCallback(() => {
    if (!userId || !apiKeyLocal) return;

    updateApiKey(userId, apiKeyLocal, fetcher)
      .then(updated => {
        if (updated) {
          setApiKey(apiKeyLocal);
          notify.success({
            title: t['docnboard.ai.api-key.notify.title.update'](),
            message: `${t['docnboard.ai.api-key.name']()} ${t['docnboard.ai.api-key.notify.update']()}`,
          });

          onClose();
        } else
          throw new Error(
            `${t['docnboard.ai.api-key.name']()} ${t['docnboard.ai.api-key.notify.not-update']()}`
          );
      })
      .catch(error => {
        if (error instanceof Error) {
          notify.error({
            title: t['docnboard.ai.api-key.notify.title.update'](),
            message: error.message,
          });
        }
      });
  }, [userId, apiKeyLocal, fetcher, t, onClose, setApiKey]);

  return (
    <>
      <ModalHeader
        title={`${t['docnboard.ai.api-key.title.update']()} ${t['docnboard.ai.api-key.name']()}`}
        subTitle=""
      />
      <AuthContent>
        {`${description}
          ${t['docnboard.ai.api-key.name']()}
          ${t['docnboard.ai.api-key.description.update']()}`}
      </AuthContent>
      <Wrapper
        marginTop={30}
        marginBottom={50}
        className={styles.aiChatUpdateApiKeyWrapperStyle}
      >
        <Input
          name="updateApiKey"
          type={showApiKey ? 'input' : 'password'}
          size="extraLarge"
          placeholder={t['docnboard.ai.api-key.input.placeholder']()}
          value={apiKeyLocal}
          onChange={onApiKeyChange}
          className={styles.aiChatUpdateApiKeyButtonStyle}
        />
        <IconButton onClick={onTogleShowApiKey}>
          {showApiKey ? <EyeSlashIcon /> : <EyeOpenIcon />}
        </IconButton>
      </Wrapper>

      <Button
        variant="primary"
        size="extraLarge"
        style={{ width: '100%' }}
        disabled={!apiKeyLocal}
        onClick={onUpdateAPIKey}
      >
        {t['docnboard.ai.api-key.title.update']()}
      </Button>
    </>
  );
};
