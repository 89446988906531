import {
  type IBannerButtons,
  MotionBanner,
} from '@affine/component/ui/cookie-banner';
import { useCallback, useEffect, useState } from 'react';

import { CookieSvg } from './icons';
import { cookieLinkStyle, cookieTextStyle } from './index.css';

const text =
  'Мы используем cookie. Это позволяет нам анализировать взаимодействие посетителей с сайтом и делать его лучше. Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов cookie. Посещая страницы сайта вы соглашаетесь с нашей ';
const linkText = 'Политикой конфиденциальности';
const link = 'https://mkskom.ru/legal-documents#policy';

export const CookieBanner = () => {
  const [show, setShow] = useState<boolean>(true);
  useEffect(() => {
    const cookies = localStorage.getItem('dnb-cookie-consent');
    if (!cookies) return;
    setShow(false);
  }, []);

  const handleClick = useCallback(() => {
    localStorage.setItem('dnb-cookie-consent', 'true');
    setShow(false);
  }, []);

  const buttons: IBannerButtons[] = [
    {
      type: 'secondary',
      text: 'Принять',
      color: false,
      onClick: handleClick,
    },
  ];

  const render = () => {
    return (
      <span className={cookieTextStyle}>
        {text}
        <a
          target="_blank"
          className={cookieLinkStyle}
          href={link}
          rel="noreferrer"
        >
          {linkText}
        </a>
      </span>
    );
  };

  return (
    <MotionBanner
      icon={CookieSvg}
      buttons={buttons}
      renderContent={render}
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: show ? 1 : 0, y: show ? 0 : '100%' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    />
  );
};
