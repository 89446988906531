//DNB_FIX использовать свою компоненту select-permission
import { SelectPermissions } from '@affine/component/affine-select-permission';
import { UserAccessRole } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { useCallback, useEffect, useState } from 'react';

import { ConfirmModal } from '../../ui/modal';
import { AuthInput } from '../auth-components';
import { emailRegex } from '../auth-components/utils';
import * as styles from './styles.css';

export interface InviteModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: (params: { email: string; permission: UserAccessRole }) => void;
  isMutating: boolean;
  //DNB_FIX
  permissions: UserAccessRole[] | null;
}

const defaultPermision = UserAccessRole.Read;

export const InviteModal = ({
  open,
  setOpen,
  onConfirm,
  isMutating,
  //DNB_FIX
  permissions,
}: InviteModalProps) => {
  const t = useI18n();
  const [inviteEmail, setInviteEmail] = useState('');
  const [permission, setPermission] = useState(defaultPermision);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleConfirm = useCallback(() => {
    if (!emailRegex.test(inviteEmail)) {
      setIsValidEmail(false);
      return;
    }
    setIsValidEmail(true);

    onConfirm({
      email: inviteEmail,
      permission,
    });
  }, [inviteEmail, onConfirm, permission]);

  const handleChangePermission = (value: UserAccessRole) => {
    setPermission(value);
  };

  useEffect(() => {
    if (!open) {
      setInviteEmail('');
      setIsValidEmail(true);
    }
  }, [open]);

  return (
    <ConfirmModal
      open={open}
      onOpenChange={setOpen}
      title={t['Invite Members']()}
      description={t['Invite Members Message']()}
      cancelText={t['com.affine.inviteModal.button.cancel']()}
      contentOptions={{
        ['data-testid' as string]: 'invite-modal',
        style: {
          padding: '20px 26px',
        },
      }}
      confirmText={t['Invite']()}
      confirmButtonOptions={{
        loading: isMutating,
        variant: 'primary',
        ['data-testid' as string]: 'confirm-enable-dnb-cloud-button',
      }}
      onConfirm={handleConfirm}
    >
      {/*TODO: check email & add placeholder*/}
      <div className={styles.inviteContent}>
        <AuthInput
          disabled={isMutating}
          placeholder="email@example.com"
          value={inviteEmail}
          onChange={setInviteEmail}
          error={!isValidEmail}
          errorHint={
            isValidEmail ? '' : t['com.affine.auth.sign.email.error']()
          }
          onEnter={handleConfirm}
          size="large"
          classNameRoot={styles.inviteAuthInput}
        />
        {/* //DNB_FIX использовать свою компоненту select-permission */}
        <SelectPermissions
          onChange={handleChangePermission}
          permissions={permissions}
        />
      </div>
    </ConfirmModal>
  );
};
